@use '../abstracts' as *;

.hero {
  &__paragraph {
    margin-bottom: $section-gap;
  }

  &__img {
    display: block;
    margin-inline: auto;
  }
}
