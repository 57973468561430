@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  font-size: 10px;
}

html *, html :before, html :after {
  box-sizing: border-box;
}

body {
  min-height: 100%;
  color: #2a3a4c;
  max-width: 602px;
  margin: 0 auto;
  padding: 32px 24px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.6rem;
}

section + section, header + main, main + footer {
  margin-top: 48px;
}

article + article {
  margin-top: 24px;
}

h1, h2 {
  margin: 0 0 24px;
  font-weight: 400;
}

h1 {
  font-size: 40px;
  line-height: 36px;
}

h2 {
  font-size: 30px;
  line-height: 33px;
}

p {
  margin: 0;
}

strong, b {
  font-weight: 600;
}

a {
  color: #1e90ff;
  -webkit-text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration: underline rgba(0, 0, 0, 0);
  transition: all .3s ease-out;
}

a:hover, a:active, a:target {
  -webkit-text-decoration-color: #1e90ff;
  text-decoration-color: #1e90ff;
}

.header__logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.hero__paragraph {
  margin-bottom: 48px;
}

.hero__img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.how-does-work__text-under-headline {
  margin-bottom: 24px;
}

/*# sourceMappingURL=index.8e3c4bbc.css.map */
