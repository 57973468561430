@use '../abstracts' as *;

html {
  font-size: 10px;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

body {
  min-height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  color: $text-color;
  font-size: 1.6rem;
  max-width: 602px;
  padding: 32px 24px;
  margin: 0 auto;
}

section + section,
header + main,
main + footer {
  margin-top: $section-gap;
}

article + article {
  margin-top: $headline-gap;
}
