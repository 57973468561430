@use '../abstracts' as *;

h1,
h2 {
  margin: 0 0 $headline-gap 0;
  font-weight: 400;
}

h1 {
  font-size: 40px;
  line-height: 36px;
}

h2 {
  font-size: 30px;
  line-height: 33px;
}

p {
  margin: 0;
}

strong,
b {
  font-weight: 600;
}

a {
  color: $brand-blue;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.3s ease-out;
}

a:hover,
a:active,
a:target {
  text-decoration-color: $brand-blue;
}
